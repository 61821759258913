.drop-container {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #DCDCDC;background:#F4F4F4;    min-height: 118px;
}

.upload-icon {
    width: 50px;
    height: 50px;
    background: url(./images/upload.png) no-repeat center center; 
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
}

.file-input {
    display: none;
}

.file-upload-btn {
    color: white;
    text-transform: uppercase;
    outline: none;
    background-color: #4aa1f3;
    font-weight: bold;
    padding: 8px 15px;
    margin-bottom: 5px;
}
.drop-divider .border-bottom.border-bottom-2{border-color:#A0A0A0 !important;}
.drop-message{font-size:14px;}
.drop-message span{cursor:pointer; border-bottom:1px solid;}  